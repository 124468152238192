<template>
  <v-container class="pa-0">
    <ReceiptDetail :article="article" v-if="article"
  /></v-container>
</template>
<script>
import ReceiptDetail from "@/components/article/RecipeDetail.vue";
import article from "~/mixins/article";
export default {
  name: "Recipe",
  components: { ReceiptDetail },
  mixins: [article],
  data() {
    return {
      article: null
    };
  },
  methods: {
    setTitle() {
      this.$route.meta.title = this.article.title;
      global.EventBus.$emit("resetNavbar");
    }
  },
  async created() {
    await this.reload(this.$route.params.pageName);
    this.setTitle();
  },
  watch: {
    "$route.params.pageName": async function(pageName) {
      await this.reload(pageName);
      this.setTitle();
    }
  }
};
</script>
