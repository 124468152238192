<template>
  <div class="ingredient-list my-3">
    <h4>{{ $t("recipes.ingredients.title") }}</h4>
    <div
      class="d-flex align-center pb-1"
      v-for="ingredient in ingredients"
      :key="ingredient.ingredient_id"
    >
      <span>
        - {{ ingredient.amount }} {{ ingredient.unit }}
        {{ ingredient.ingredient }}</span
      >
    </div>
    <v-btn
      rounded
      large
      block
      depressed
      color="primary"
      @click="addProducts()"
      class="mt-3"
      :disabled="disableAddBtn"
      data-html2canvas-ignore="true"
      ><v-icon left>$listaSpesa</v-icon
      >{{ $t("recipes.ingredients.addToList") }}</v-btn
    >
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "IngredientList",
  props: {
    ingredients: {
      type: Array,
      required: true
    },
    disableAddBtn: { type: Boolean, default: false }
  },
  methods: {
    ...mapActions({
      addShoppingItems: "custom/addShoppingItems"
    }),
    addProducts() {
      const newArray = this.ingredients.map(item => ({
        label: `${item.amount ? item.amount : ""} ${
          item.unit ? item.unit : ""
        } ${item.ingredient ? item.ingredient : ""}`.trim(),
        selected: false
      }));
      this.addShoppingItems(newArray);
      global.EventBus.$emit("success", {
        message: global.vm.$t("shoppingList.itemsAdded", {
          quantity: newArray.length
        })
      });
    }
  }
};
</script>
